import React from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';

interface Props {
  title?: string;
  keywords?: string;
  description?: string;
  canonical?: string; // New prop for canonical URL
  ogImage?: string; // New prop for dynamic og:image
}

const CustomNextSeo: React.FC<Props> = ({
  title,
  keywords,
  description,
  canonical,
  ogImage,
}) => {
  return (
    <>
      <NextSeo
        title={`${
          title || 'Bishopi.io'
        }`}
        additionalMetaTags={[
          {
            name: 'keywords',
            content:
              keywords ||
              'free domain name appraisal, domain name appraisal, domain name appraisal tool, domain name appraisal free, domain name appraisal free online, domain name appraisal free tool,',
          },
          {
            name: 'robots', // Add robots meta tag for indexing
            content: 'index, follow', // Allow search engines to index and follow links
          },
          {
            property: 'og:title',
            content: title || 'Bishopi', // Use the same title for og:title
          },
          
          {
            property: 'og:description',
            content: description || 'Free domain appraisal and domain investment tools', // Use the same description for og:description
          },
          {
            property: 'og:url',
            content: canonical || 'https://bishopi.io/', // Use the canonical URL or a default URL
          },
          // Add more OpenGraph tags as needed
        ]}
        description={
          description || 'Free domain name appraisal'
        }
      />
      <Head>
        {canonical && <link rel="canonical" href={canonical} />} {/* Add canonical link */}
      </Head>
    </>
  );
};

export default CustomNextSeo;
