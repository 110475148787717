import React, { useEffect } from 'react'

import MainLayout from 'layouts/MainLayout/MainLayout'

import { Page } from 'models/page.interface'
import { getCustomServerSideProps } from 'utils/getCustomServerSideProps'
import faqData from '../data/homepage-faqs-data.json'
import APIDocumentation from 'components/Home/APIDocumentation/APIDocumentation'
import { CtaSection } from 'components/Home/CtaSection/CtaSection'
import { DomainBackorderingSection } from 'components/Home/DomainBackorderingSection/DomainBackorderingSection'
import { FeaturesSection } from 'components/Home/FeaturesSection/FeaturesSection'
import { ToolsSection } from 'components/Home/ToolsSection/ToolsSection'
import { Hero } from 'components/Home/Hero/Hero'
import FAQSection from 'components/common/sections/FAQSection/FAQSection'
import { StatsSection } from 'components/common/sections/StatsSection/StatsSection'
import CustomNextSeo from 'components/CustomNextSeo'
import { useRouter } from 'next/router'
import { localStorageService } from 'services/localStorage.service'
import { LocalStorageKeys } from 'enums/local-storage-keys'
import { useTrackAffiliateClick } from '../hooks/useTrackAffiliateClick'

interface Props {}

const Home: Page<Props> = ({}) => {
  const router = useRouter()

  const { mutateAsync: trackAffiliateClick } = useTrackAffiliateClick()
  const [tracked, setTracked] = React.useState(false)
  const urlInviteToken = router.query.invite_token?.toString()
  const urlInviteName = router.query.name?.toString()

  React.useEffect(() => {
    if (tracked) return;
    const params = localStorageService.get(LocalStorageKeys.referralParams)
    let parsedParams: any = {}

    try {
      parsedParams = JSON.parse(params) || {}
    } catch (e) {
      // noop
    }

    if (parsedParams?.inviteToken !== urlInviteToken) {
      trackAffiliateClick(urlInviteToken).catch(() => {
        // noop
      })
      setTracked(true)
    }
  }, [urlInviteToken, tracked, trackAffiliateClick]);

  useEffect(() => {
    if (urlInviteToken || urlInviteName) {
      localStorageService.set(
        LocalStorageKeys.referralParams,
        JSON.stringify({
          inviteToken: urlInviteToken,
          inviteName: urlInviteName,
        })
      )
    }
  }, [urlInviteToken, urlInviteName])
  return (
    <>
      <CustomNextSeo
        canonical="https://bishopi.io/"
        title="#1 AI-Powered Domain Name Tools & Services"
        keywords="domain tools,domain name tools,domain value,domain appraisal,domain name value,domain worth,domain valuation,domain name evaluator,domain value estimator,domain name appraisal,domain name valuation,free domain name valuation,keyword research tools"
        description="Bishopi is the #1 AI-Powered Domain Name Tools & Services. We provide domain name tools, domain backordering tools, domain appraisal tools, domain flipping tools, and keyword research tools."
      />
      <Hero />
      <FeaturesSection />
      <ToolsSection />
      <DomainBackorderingSection />
      <APIDocumentation />
      <CtaSection />
      <FAQSection data={faqData} />
      <StatsSection />
    </>
  )
}

export const getServerSideProps = async (ctx) => {
  const { props: customProps } = await getCustomServerSideProps(ctx)

  return {
    props: {
      ...customProps,
    },
  }
}

Home.getLayout = (page: React.ReactElement) => <MainLayout>{page}</MainLayout>

export default Home
